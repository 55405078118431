import { apiGet } from 'vivino-js/apiClient';
import generateQueryUrl from 'vivino-js/generateQueryUrl';

export function fetchToplists({
  filter,
  includeItems = undefined,
  limit = undefined,
  startAt = undefined,
}) {
  const params = [];
  if (filter) {
    params.push({ name: 'filter', value: filter });
  }
  if (includeItems) {
    params.push({ name: 'include_items', value: true });
  }
  if (limit) {
    params.push({ name: 'limit', value: limit });
  }
  if (startAt || startAt === 0) {
    params.push({ name: 'start_at', value: startAt });
  }

  return apiGet({
    url: generateQueryUrl({ baseUrl: '/api/toplists', params }),
  });
}

export const fetchToplistItems = (toplistId) =>
  apiGet({
    url: `/api/toplists/${toplistId}`,
  });
