import PropTypes from 'prop-types';
import React from 'react';
import { WrapCartItemSourceContext } from 'vivino-js/context/CartItemSourceContext';
import BannerCardPresentational from 'vivino-ui/atoms/BannerItem';

import styles from './bannerGrid.scss';

const BannerGridPresentational = ({ banners = [] }) => {
  return (
    <div className={styles.bannerGrid}>
      {banners.map((banner, index) => (
        <div data-testid="bannerItem" className={styles.bannerItem} key={index}>
          <WrapCartItemSourceContext cartItemSource={banner.cartItemSource}>
            <BannerCardPresentational
              cardImage={banner.card_image}
              cardTitle={banner.card_title}
              link={banner.link}
            />
          </WrapCartItemSourceContext>
        </div>
      ))}
    </div>
  );
};

BannerGridPresentational.propTypes = {
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      card_image: PropTypes.string.isRequired,
      card_title: PropTypes.string.isRequired,
      link: PropTypes.string,
      cartItemSource: PropTypes.string, // For dynamic cart item source per item
    })
  ),
};

export default BannerGridPresentational;
