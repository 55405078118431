import {
  getCountryName,
  getCurrentCountryCode,
  getCurrentStateCode,
  getStateName,
  isVCCountry,
  t,
} from '@vivino/js-web-common';

export const getWelcomeLocationName = () => {
  let location = '';
  const countryCode = getCurrentCountryCode();

  if (isVCCountry(countryCode)) {
    const stateCode = getCurrentStateCode();
    if (stateCode) {
      location = getStateName(stateCode);
    } else {
      location = getCountryName(countryCode);
    }
  }

  return location || t('welcome.index.your_area');
};
