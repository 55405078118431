import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Anchor from 'vivino-js/atoms/Anchor';
import { CartItemSourceContext } from 'vivino-js/context/CartItemSourceContext';
import { MerchandizingCampaignContext } from 'vivino-js/context/MerchandizingContext';
import { getCartItemSource, getCartItemSourceProps } from 'vivino-js/helpers/cartItemSources';
import { isExploreUrl } from 'vivino-js/vivinoUrls';
import Card from 'vivino-ui/atoms/Card';

import styles from './bannerItem.scss';

const BannerItemPresentational = ({ cardImage, cardTitle, link = '' }) => {
  const imageStyle = {
    backgroundImage: `url(${cardImage})`,
  };

  const banner = (
    <div className={styles.bannerItem}>
      <div data-testid="bannerImage" className={styles.image} style={imageStyle}>
        &nbsp;
      </div>
      <div className={styles.title}>{cardTitle}</div>
    </div>
  );

  const cartItemSourceType = useContext(CartItemSourceContext);
  const campaignId = useContext(MerchandizingCampaignContext);
  const cartItemSourceParam = getCartItemSource(cartItemSourceType, campaignId);

  if (link) {
    const cartItemSourceProps = isExploreUrl(link)
      ? getCartItemSourceProps(cartItemSourceParam)
      : {};

    return (
      <Card>
        <Anchor
          data-testid="bannerAnchor"
          className={styles.anchor}
          href={link}
          {...cartItemSourceProps}
        >
          {banner}
        </Anchor>
      </Card>
    );
  }

  return banner;
};

BannerItemPresentational.propTypes = {
  cardImage: PropTypes.string.isRequired,
  cardTitle: PropTypes.string.isRequired,
  link: PropTypes.string,
};

export default BannerItemPresentational;
