import React from 'react';
import { ORDER_BY_DISCOUNT, ORDER_BY_RATINGS_COUNT, ORDER_DESC } from 'vivino-js/api/explore';
import { priceRange } from 'vivino-js/apiPropTypes';
import { CART_ITEM_SOURCE_TYPES } from 'vivino-js/context/CartItemSourceContext';
import { RatingThresholds } from 'vivino-js/helpers/vivinoRating';
import t from 'vivino-js/translationString';
import { explorerUrl } from 'vivino-js/vivinoUrls';

import BannerGrid from 'vivino-ui/components/BannerGrid';

import styles from './explorerAds.scss';
import * as images from './images';

const TRANSLATIONS = {
  diveIntoMillionsOfRatings:
    'components.welcome_page.explorer_ads.explore_tile.dive_into_millions_of_ratings',
  greatOffersRightNow: 'components.welcome_page.explorer_ads.offer_tile.great_offers_right_now',
  exploreTheMostPopularWines:
    'components.welcome_page.explorer_ads.explore_tile.explore_the_most_popular_wines',
};

const getExplorerUrlParams = (priceRange) => ({
  orderBy: ORDER_BY_DISCOUNT,
  order: ORDER_DESC,
  minRating: RatingThresholds.GOOD,
  priceRangeMin: priceRange?.defaults?.minimum || 0,
  priceRangeMax: priceRange?.defaults?.maximum || 0,
});

const ExplorerAds = ({ priceRange }) => {
  const bannerItems = [
    {
      card_image: images.offers,
      card_title: t(TRANSLATIONS.greatOffersRightNow),
      // @ts-ignore
      link: explorerUrl(getExplorerUrlParams(priceRange)),
      cartItemSource: CART_ITEM_SOURCE_TYPES.FRONTPAGE_OFFERS_EXPLORE_LINK,
    },
    {
      card_image: images.popular,
      card_title: t(TRANSLATIONS.exploreTheMostPopularWines),
      // @ts-ignore
      link: explorerUrl({
        ...getExplorerUrlParams(priceRange),
        orderBy: ORDER_BY_RATINGS_COUNT,
      }),
      cartItemSource: CART_ITEM_SOURCE_TYPES.FRONTPAGE_EXPLORE_LINK,
    },
  ];

  return (
    <section className={styles.exploreAds}>
      <div className={styles.headline}>{t(TRANSLATIONS.diveIntoMillionsOfRatings)}</div>
      <BannerGrid banners={bannerItems} />
    </section>
  );
};

ExplorerAds.propTypes = {
  priceRange,
};

export default ExplorerAds;
