import { isEmpty } from '@vivino/js-web-common';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { fetchPrices } from 'vivino-js/api/prices';
import { fetchToplists } from 'vivino-js/api/toplists';
import { pricesKeyedByVintage } from 'vivino-js/helpers/price';
import { withHeader } from 'vivino-js/legacy/WithHeader';
import { withToplistControls } from 'vivino-js/legacy/WithToplistControls';
import { WineBandSkeleton } from 'vivino-ui/atoms/Skeletons';

import WineBand from 'vivino-js/components/WineBand';

const WineBandWithControls = withHeader(withToplistControls(WineBand));

const TopListBandWithControls = ({
  currencyCode,
  headline = '',
  description = '',
  topLists,
  trackingName,
  trackingSource,
  minimumItemNumber = 6,
}) => {
  const [activeListIndex, setActiveListIndex] = useState(0);
  const [topListsState, setToplistsState] = useState(topLists);
  const [activeToplistText, setActiveToplistText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [vintages, setVintages] = useState([]);
  const [vintageIds, setVintageIds] = useState([]);
  const [topListIds, setToplistIds] = useState([]);
  const [prices, setPrices] = useState({});
  const [vintageIdsOfFetchedPrices, setVintageIdsOfFetchedPrices] = useState(new Set());

  useEffect(() => {
    if (topLists) {
      setToplistIds(topLists.map((toplist) => toplist.id));
      setStateFromToplist(topLists, 0);
    } else {
      fetchToplists({
        filter: 'discount',
        includeItems: true,
      }).then((data) => {
        setToplistIds(data.toplists.map((topList) => topList.id));
        setToplistsState(data.toplists);
        setStateFromToplist(data.toplists, 0);
      });
    }
  }, []);

  useEffect(() => {
    setPricesState();
  }, [JSON.stringify(vintages)]);

  const loadToplist = (e) => {
    e.preventDefault();
    const newIndex = parseInt(e.currentTarget.dataset.toplistIndex, 10);
    if (newIndex !== activeListIndex) {
      setIsLoading(true);
      setStateFromToplist(topListsState, newIndex);
    }
  };

  const setStateFromToplist = (topLists, index) => {
    if (topLists?.length > 0) {
      const topList = topLists[index];
      const { vintageIds, vintages } = topList.items.reduce(
        (acc, item) => {
          acc.vintageIds.push(item.vintage.id);
          acc.vintages.push(item.vintage);
          return acc;
        },
        {
          vintageIds: [],
          vintages: [],
        }
      );

      setVintages(vintages);
      setVintageIds(vintageIds);
      setActiveToplistText(topList.name);
      setActiveListIndex(index);
      // if toplist has not vintages, it will have no effect on setPricesState
      // therefore loading state will be not cleared, so clearing loading here
      if (!vintages?.length && isLoading) {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const setPricesState = () => {
    if (!vintages.length) {
      return;
    }

    const vintageIds = vintages.reduce((memo, vintage) => {
      const vintageId = vintage.id;
      if (!vintageIdsOfFetchedPrices.has(vintageId)) {
        memo.push(vintageId);
      }
      return memo;
    }, []);

    if (isEmpty(vintageIds)) {
      setIsLoading(false);
      return;
    }

    fetchPrices({ vintageIds })
      .then((priceData) => {
        // need to set `withVintageSubstitution = true` to pricesKeyedByVintage
        // so we get prices for other vintages when the current one is not available
        const newPrices = vintageIds.reduce(pricesKeyedByVintage(priceData, true), {});
        setPrices({
          ...prices,
          ...newPrices,
        });

        vintageIds.forEach((vintageId) => vintageIdsOfFetchedPrices.add(vintageId));
        setVintageIdsOfFetchedPrices(vintageIdsOfFetchedPrices);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <WineBandSkeleton />;
  }

  if ((topLists?.length || 0) < 1) {
    return null;
  }

  return (
    <div
      className="market-band market-band--multiple-toplists"
      data-track-type="pi"
      data-module-name={headline}
      id="toplistsBand"
    >
      <WineBandWithControls
        // Used in WineBand
        vintages={vintages}
        prices={prices}
        showAllUrl={`/toplists/${topListsState?.[activeListIndex]?.id}`}
        //
        // Used in WithHeader
        listType={'multiple-toplists'}
        headline={headline}
        description={description}
        trackingSource={trackingSource}
        trackingName={trackingName}
        // image
        // merchantState
        // merchantCountryCode
        // merchantLink
        //
        // Used in withToplistControls
        activeListIndex={activeListIndex}
        currencyCode={currencyCode}
        loadToplist={loadToplist}
        // trackingSource={trackingSource}
        // trackingName={trackingName}
        toplistsIds={topListIds}
        activeToplistText={activeToplistText}
        //
        // Used in WithMinimumItems but not in this file - copy/pasted
        itemsNumber={vintageIds.length}
        minimumItemNumber={minimumItemNumber}
        isLoading={isLoading}
      />
    </div>
  );
};

TopListBandWithControls.propTypes = {
  currencyCode: PropTypes.string,
  activeToplistText: PropTypes.string,
  // Required Props
  headline: PropTypes.string,
  description: PropTypes.string,
  topLists: PropTypes.arrayOf(PropTypes.object),
  trackingName: PropTypes.string.isRequired,
  trackingSource: PropTypes.string.isRequired,
  // Optional Props
  minimumItemNumber: PropTypes.number,
};

export default TopListBandWithControls;
